@import "mixins";
@import "variables";

.container {
    background-color: #090500;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .icon {
        width: 180px;
        height: 180px;
        margin-top: 80px;
        background-image: url("../resources/V2/logo-01_v2.svg");
    }

    .text1 {
        margin-top: 30px;
        width: $mobile-size - 40px;
        text-align: center;
        @include fontBold(36px, white);
    }
    .text2 {
        margin-top: 20px;
        width: 1000px;
        text-align: center;
        @include fontThin(20px, white);
        line-height: 30px;

        @media (max-width: $mobile-size) {
            width: calc(100% - 40px);
        }

        b {
            @include fontBold(20px, white);
        }
    }
}
