@import "mixins";
@import "variables";

.container {
    padding-top: 80px;
    padding-bottom: 60px;
    width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;
    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .text1 {
        @include fontBold(36px, black);
    }

    .text2 {
        @include fontThin(20px, #090500);
        width: calc(100% - 40px);
        max-width: 1000px;
        text-align: center;
        margin-top: 20px;
    }

    .cards {
        margin-top: 80px;
        width: 1000px;

        @media (max-width: 1000px) {
            width: 480px;
        }

        @media (max-width: 520px) {
            width: 220px;
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .card {
            width: 220px;
            height: 100px;
            margin-bottom: 40px;

            border-radius: 10px;
            box-shadow: 0 2px 10px 0 #ebebeb;
            border: solid 1px #f0f0f0;
            background-color: #fff;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .dummy {
            width: 220px;
            height: 100px;
            margin-bottom: 40px;
        }
    }
}
