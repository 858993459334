@import "mixins";
@import "variables";

.container {
    padding-top: 80px;
    padding-bottom: 100px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #f5f5f5;

    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .text1 {
        @include fontBold(36px, black);
    }

    .cards_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 400px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
        }

        .cards {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            height: 100%;
            width: 100%;

            .card {
                width: 300px;
                height: 280px;
                margin-left: 25px;
                margin-right: 25px;
                overflow: hidden;
                text-decoration: none;
                border-radius: 10px;
                box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.2);
                background-color: #fff;
                display: flex;
                flex-direction: column;

                &:hover {
                    opacity: 0.7;
                }

                .image {
                    width: 300px;
                    height: 162px;
                }

                .title {
                    @include fontThin(16px, #2d2d2d);
                    line-height: 23px;

                    margin-top: 20px;
                    margin-left: 20px;
                    margin-right: 20px;

                    overflow: hidden;
                    flex: 1;
                }

                .date {
                    @include fontThin(14px, #b4b4b4);
                    line-height: 23px;
                    text-align: right;
                    margin-top: 9px;
                    margin-bottom: 20px;
                    margin-right: 20px;
                }
            }
        }
    }
}
