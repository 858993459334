@import "variables";

@mixin fontThin($size, $color) {
    font-family: "gotham_book";
    font-size: $size;
    color: $color;

    // @media (max-width: $mobile-size) {
    //     font-size: $size * 0.8;
    // }
}

@mixin font($size, $color) {
    font-family: "gotham_medium";
    font-size: $size;
    color: $color;

    // @media (max-width: $mobile-size) {
    //     font-size: $size * 0.8;
    // }
}

@mixin fontBold($size, $color) {
    font-family: "gotham_bold";
    font-size: $size;
    color: $color;

    // @media (max-width: $mobile-size) {
    //     font-size: $size * 0.8;
    // }
}

@mixin box() {
    border-radius: 10px;
    box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.4);
    border: solid 1px #3b3531;
    background-color: #1a1a1e;
}
