@font-face {
    font-family: "gotham_bold";
    src: url(./resources/Gotham-Bold.ttf);
}

@font-face {
    font-family: "gotham_medium";
    src: url(./resources/Gotham-Medium.ttf);
}

@font-face {
    font-family: "gotham_book";
    src: url(./resources/Gotham-Book.ttf);
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
