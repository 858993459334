@import "mixins";
@import "variables";

.container {
    padding-top: 80px;
    padding-bottom: 100px;
    width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #090500;
    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .text1 {
        @include fontBold(36px, white);
    }

    .cards {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 80px;
        position: relative;
        padding-top: 60px;
        padding-bottom: 60px;

        // background-color: red;
        .bar {
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);

            width: 2px;
            height: calc(100%);

            background-image: linear-gradient(
                to bottom,
                #090500,
                #fff200 10%,
                #ffea00 90%,
                #090500
            );

            @media (max-width: $mobile-size) {
                left: 20%;
            }
        }

        .card {
            display: flex;
            flex-direction: row;
            margin-left: calc(50% - 26px);
            z-index: 10000;

            &.left {
                flex-direction: row-reverse;
                margin-left: 0px;
                margin-right: calc(50% - 26px);
            }

            @media (max-width: $mobile-size) {
                margin-left: calc(20% - 26px);

                &.left {
                    flex-direction: row;
                    margin-left: calc(20% - 26px);
                    margin-right: 0px;
                }
            }

            .dot {
                margin-top: -16px;
                width: 52px;
                height: 52px;
                position: relative;

                .dot1 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);

                    width: 52px;
                    height: 52px;
                    opacity: 0.67;
                    -webkit-filter: blur(10.1px);
                    filter: blur(10.1px);
                    background-color: #ffe700;
                    border-radius: 50%;

                    @media (max-width: $mobile-size) {
                        filter: none;
                        opacity: 0;
                    }
                }

                .dot2 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);

                    width: 34px;
                    height: 34px;
                    background-color: rgba(#ffe700, 0.3);
                    border-radius: 50%;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .dot3 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);

                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background-color: #ffe700;
                }
            }

            .content {
                flex: 1;

                display: flex;
                flex-direction: column;
                margin-left: 30px;
                max-width: 384px;

                &.left {
                    margin-left: 0px;
                    margin-right: 30px;
                }

                @media (max-width: $mobile-size) {
                    &.left {
                        margin-left: 30px;
                        margin-right: 10px;
                    }
                }

                .title {
                    @include fontBold(24px, #ffe700);

                    &.left {
                        text-align: right;
                    }

                    @media (max-width: $mobile-size) {
                        &.left {
                            text-align: left;
                        }
                    }
                }

                .sub1 {
                    margin-top: 2px;
                    line-height: 31.5px;
                    @include font(24px, white);
                    &.left {
                        text-align: right;
                    }

                    @media (max-width: $mobile-size) {
                        &.left {
                            text-align: left;
                        }
                    }
                }

                .sub2 {
                    margin-top: 10px;
                    margin-bottom: 30px;
                    @include fontThin(16px, white);
                    &.left {
                        text-align: right;
                    }

                    @media (max-width: $mobile-size) {
                        &.left {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
