@import "mixins";
@import "variables";

.container {
    padding-top: 100px;
    padding-bottom: 78px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #090500;

    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .text1 {
        @include fontBold(36px, white);
    }

    // @keyframes bannerscroll {
    //     0% {
    //         transform: translateX(0%);
    //     }

    //     100% {
    //         transform: translateX(-50%);
    //     }
    // }
    @keyframes marquee {
        0% {
            left: 0%;
        }
        // 50% {
        //     left: -100%;
        // }
        100% {
            left: -100%;
        }
    }

    .cards_container {
        margin-top: 60px;
        height: 244px;
        width: 100%;
        position: relative;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-start;
        // overflow-x: hidden;

        .cards1 {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;

            overflow-x: hidden;

            display: flex;
            flex-direction: row;
            // animation: marquee 60s linear infinite;

            .card {
                width: 214px;
                height: 100%;
                margin-left: 13px;
                margin-right: 13px;

                padding: 0px;
                overflow: hidden;
            }
        }
    }

    .shadow {
        position: absolute;
        bottom: 69px;
        width: 100%;
        height: 140px;
        background: linear-gradient(rgba(9, 5, 0, 0), #090500);
        opacity: 0.5;
    }
}
