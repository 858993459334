$anim-duration: 0.2s;
$mobile-size: 1200px;

$width-pc1: 1200px;
$width-pc2: 800px;
$width-mobile: 100%;

$header-pc: 70px;
$header-mobile: 70px;

$footer-pc: 100px;
$footer-margin-pc: 100px;
$footer-mobile: 182px;
$footer-margin-mobile: 100px;
