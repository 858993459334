@import "mixins";
@import "variables";

.container {
    width: $mobile-size - 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #090500;

    .text1 {
        margin-top: 170px;
        text-align: center;
        @include fontBold(36px, #ececec);

        @media (max-width: $mobile-size) {
            margin-top: 80px;
        }
    }
    .text2 {
        margin-top: 20px;
        text-align: center;
        line-height: 30px;
        width: calc(100% - 40px);
        max-width: 1000px;
        @include fontThin(20px, white);
    }

    .buttons {
        width: 100%;
        margin-top: 106px;
        margin-bottom: 240px;

        display: flex;
        flex-direction: row;
        justify-content: center;

        @media (max-width: $mobile-size) {
            width: auto;
            width: 480px;
            margin-top: 60px;
            margin-bottom: 60px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @media (max-width: 600px) {
            width: auto;
            height: 920px;
            margin-top: 60px;
            margin-bottom: 60px;
            flex-direction: column;
            justify-content: space-between;
        }

        .box {
            @include box();
            cursor: pointer;
            position: relative;

            width: 218px;
            height: 198px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 30px;
            margin-right: 30px;
            overflow: hidden;

            text-decoration: none;

            @media (max-width: $mobile-size) {
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 40px;
            }

            @media (max-width: 600px) {
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 0px;
            }

            .icon {
                &.twitter {
                    margin-top: 60px;
                    margin-bottom: 36px;
                    width: 50px;
                    height: 42px;
                    background-size: cover;
                    background-image: url("../resources/logo-twitter.svg");
                }

                &.telegram {
                    margin-top: 63px;
                    margin-bottom: 30px;
                    width: 52px;
                    height: 45px;
                    background-size: cover;
                    background-image: url("../resources/logo-telegram.svg");
                }

                &.medium {
                    margin-top: 63px;
                    margin-bottom: 35px;
                    width: 49px;
                    height: 38px;
                    background-size: cover;
                    background-image: url("../resources/logo-medium.svg");
                }

                &.forum {
                    margin-top: 63px;
                    margin-bottom: 35px;
                    width: 51px;
                    height: 38px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url("../resources/logo-forum.svg");
                }
            }

            .button {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-decoration: none;

                width: 104px;
                height: 28px;
                border-radius: 16px;
                border: solid 2px #2e2b29;
                background-color: #1a1a1e;

                font-family: "gotham_medium";
                font-size: 14px;
                color: rgba(white, 0.3);
            }

            &:hover {
                .icon {
                    &.twitter {
                        background-image: url("../resources/logo-twitter-on.svg");
                    }

                    &.telegram {
                        background-image: url("../resources/logo-telegram-on.svg");
                    }

                    &.medium {
                        background-image: url("../resources/logo-medium-on.svg");
                    }

                    &.forum {
                        // background-image: url("../resources/logo-forum-on.svg");
                        background-color: red;
                        -webkit-mask: url("../resources/logo-forum.svg");
                        mask: url("../resources/logo-forum.svg");
                    }
                }

                .button {
                    border: solid 2px #ffe646;
                    color: #ffe646;
                    text-decoration: none;
                }
            }
        }
    }
}
