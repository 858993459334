@import "mixins";
@import "variables";

.footer {
    height: 100px;

    display: flex;
    justify-content: center;
    background-color: white;

    @media (max-width: $mobile-size) {
        height: auto;
        padding: 20px;
    }

    .container {
        max-width: 1080px;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            align-items: flex-start;
        }

        .copyright {
            @include fontThin(16px, #a0a0a0);
            letter-spacing: -0.47px;
            flex: 1;
        }

        .button {
            margin-left: 40px;
            color: #2d2d2d;
            font-family: "gotham_bold";
            font-size: 16px;
            letter-spacing: -0.47px;

            text-decoration: none;

            &.disabled {
                opacity: 0.6;
            }

            &:not(.disabled) {
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                }
            }

            @media (max-width: $mobile-size) {
                margin-left: 0px;
                margin-top: 20px;
            }
        }
    }
}
