@import "mixins";
@import "variables";

.header {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;

    background-color: white;

    .container {
        max-width: $mobile-size;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
            margin-top: 3px;
            width: 112px;
            height: 27px;

            background-size: cover;
            background-image: url("../resources/V2/logo-v_v2.svg");

            @media (max-width: $mobile-size) {
                margin-left: 20px;
            }
        }

        .buttons {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .button {
                cursor: pointer;
                margin-left: 40px;
                @include font(16px, #2d2d2d);
                text-decoration: none;
                &:hover {
                    opacity: 0.6;
                }

                @media (max-width: $mobile-size) {
                    display: none;
                }
            }

            .webapp {
                cursor: pointer;
                margin-left: 40px;
                @include font(14px, #2d2d2d);
                text-decoration: none;
                height: 32px;
                margin: 0 0 0 40px;
                padding-left: 16px;
                padding-right: 16px;
                border-radius: 16px;
                background-color: #ffe646;

                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    opacity: 0.6;
                }

                @media (max-width: $mobile-size) {
                    display: none;
                }
            }
        }

        .menu {
            display: none;
            @media (max-width: $mobile-size) {
                cursor: pointer;
                display: flex;
                margin-right: 20px;
                width: 42px;
                height: 42px;
                background-image: url("../resources/ico-menu.svg");

                &.show {
                    background-image: url("../resources/ico-menu-cross.svg");
                }
            }
        }
    }

    .mobile_menu_container {
        position: fixed;
        left: 0px;
        top: 66px;

        width: 100%;
        height: calc(100vh - 66px);

        background-color: rgba(black, 0.3);
        .mobile_menu {
            display: flex;

            flex-direction: column;
            background-color: white;

            .item {
                cursor: pointer;
                height: 80px;
                padding-left: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-decoration: none;
                font-family: "gotham_bold";
                font-size: 24px;
                color: #2d2d2d;
            }
        }
    }
}
