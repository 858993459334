@import "mixins";
@import "variables";

.section0 {
    display: flex;

    .container {
        display: block;
        overflow: hidden;

        position: relative;
        width: 100vw;
        height: 100vh;
        background-color: #090500;

        .video {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0px;
            width: 100vh * 1.7777778;
            height: 100vh;
            border: 0px;
            overflow: hidden;
            position: relative;
        }

        .text1 {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;

            @media (max-width: 1000px) {
                display: none;
            }
        }

        .text2 {
            display: none;
            @media (max-width: 1000px) {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
            }
        }

        .text1_1 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            @include fontBold(96px, white);
            color: white;
            opacity: 0.8;
            font-weight: bold;
            width: 100%;
            text-align: center;
            line-height: 64px;
            letter-spacing: -1px;
        }

        .text1_2 {
            position: absolute;
            top: calc(50% + 5px);
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            @include fontBold(96px, #ffe646);
            color: #ffe646;
            opacity: 0.8;
            font-weight: bold;
            position: absolute;
            width: 100%;
            text-align: center;

            line-height: 64px;
            letter-spacing: -1px;
        }
    }
}
